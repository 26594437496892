<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-carousel`">
      <Carousel :carouselData="swipeList" height="600px">
      </Carousel>
    </div>
    <main :class="`${prefixCls}-content`">
      <aside :class="`${prefixCls}-navs`">
        <div :class="`${prefixCls}-navs-item ${active === '/aochen/intro'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/intro')">
          公司简介
        </div>
<!--        <div :class="`${prefixCls}-navs-item ${active === '/aochen/chairman'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/chairman')">-->
<!--          董事长寄语-->
<!--        </div>-->
        <div :class="`${prefixCls}-navs-item ${active === '/aochen/culture'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/culture')">
          企业文化
        </div>
<!--        <div :class="`${prefixCls}-navs-item ${active === '/aochen/team'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/team')">-->
<!--          管理团队-->
<!--        </div>-->
        <div :class="`${prefixCls}-navs-item ${active === '/aochen/honors'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/honors')">
          资质荣誉
        </div>
        <div :class="`${prefixCls}-navs-item ${active === '/aochen/social'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/social')">
          社会责任
        </div>
<!--        <div :class="`${prefixCls}-navs-item ${active === '/aochen/video'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/aochen/video')">-->
<!--          视频中心-->
<!--        </div>-->
      </aside>
      <transition name="custom"
      mode="out-in"
      appear>
        <router-view :introductionData="aochenData.companyProfile[0]" :leadershipCare="aochenData.leadershipCare"></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
import Carousel from '../../components/Carousel/index.vue'
import { carouselMixin } from '../../mixin/carousel'
import { getBannerByChannelId } from '@/api'
export default {
  name: 'Aochen',
  components: {
    Carousel
  },
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      aochenData: {
        companyProfile: []
      },
      active: '',
      transitionName: 'left-move',
      swipeList: []
    }
  },
  mixins: [carouselMixin],
  activated () {
    this.active = this.$route.path
    // getAbout().then((res) => {
    //   this.aochenData = res.data.data
    // })
  },
  watch: {
    $route: function (val) {
      this.active = val.path
    }
  },
  created () {
    this.active = this.$route.path
    this.getBannerByChannelId()
  },
  methods: {
    getBannerByChannelId () {
      getBannerByChannelId(2).then(({ data }) => {
        this.swipeList = data.data
      })
    },
    toggleNav (path) {
      if (this.active === path) {
        return false
      }
      this.active = path
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -company;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 40px;
    &-carousel{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }
    &-content{
      display: flex;
      flex-direction: row;
      width: $content-width;
      min-height: 800px;
    }
    &-navs {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 75px;
      margin-top: 50px;
      font-size: 20px;
      &-active{
        background-color: #1E2A48 !important;
        color: #fff !important;
      }
      &-item{
        color: #000;
        text-align: center;
        width: 240px;
        background-color: #F5F7FA;
        height: 80px;
        line-height: 80px;
        cursor: pointer;
      }
    }
  }
</style>
